<template lang="pug">
h1 Welcome
</template>

<script>
export default {
  name: 'Dashboard',

  setup() {

  }
}
</script>

<style>

</style>